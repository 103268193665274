.user-profile-section {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 50px;
    width: fit-content;
    margin: auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

.user-info-section {
    display: flex;
    gap: 80px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background-color: rgb(39, 39, 39);
    padding: 20px;
}

.user-bio-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: baseline;
}

.user-bio-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.user-bio-info {
    display: flex;
    gap: 40px;
}

.user-avatar-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 150px;
    height: 150px;
}

.user-avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}



@media only screen and (max-width:766px) {

    .user-info-section {
        flex-direction: column;
        gap: 20px;
    }

    .user-bio-header {
        flex-direction: column;
        gap: 20px;
        align-self: center;
    }

    .user-bio-footer {
        align-self: center;
    }
}


@media only screen and (max-width:500px) {
    .user-bio-info {
        gap: 20px;
    }

    .user-info-section {
        width: 414px;
        margin: auto;
        margin: 15px;
    }

}