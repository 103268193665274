.suggestion-section {
    position: fixed;
    top: 0;
    right: 5%;
    color: white;
    padding-top: 50px;
}

.switch-account {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}


.left-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 15px;
    border-radius: 25px;
}

.left-section:hover {
    background-color: rgb(36, 36, 36);
    cursor: pointer;
}

.account-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.switch-btn {
    color: rgb(0, 149, 246);
}

.switch-btn:hover {
    cursor: pointer;
    color: white;
}

/* accounts to follow */

.suggestion-writing {
    color: rgb(200, 200, 200)
}

.accounts-to-follow {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.to-follow-header {
    display: flex;
    gap: 100px;
    justify-content: space-between;
    margin-bottom: 20px;
}

.see-all-btn:hover {
    cursor: pointer;
}

.follow-btn:hover {
    cursor: pointer;
}

.suggestion-account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(39, 39, 39);
    padding: 15px;
    border-radius: 16px;
    gap: 15px;
}

.suggestion-account-left-side {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.suggestion-account-left-side:hover {
    cursor: pointer;
}

.followed-by {
    font-size: 0.8rem;
}

.suggestion-account-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: flex-start;
}

.follow-btn {
    height: 100%;
    display: flex;
    align-items: flex-start;
}


@media only screen and (max-width:1000px) {

    .suggestion-section {
        display: none;
    }

}