.my-logo {
    font-family: billabong;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 400;
}

.navContainer {
    position: fixed;
    background-color: rgb(39, 39, 39);
    color: white;
    width: 250px;
    height: 98%;
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    font-family: 'PT Sans', sans-serif;
    border-right: 0.1px solid grey;
    border-radius: 16px;

}

.navIcon-box {
    display: flex;
    justify-content: baseline;
    align-items: center;
    width: 150px;
    gap: 20px;
    border-radius: 25px;
    padding: 10px;
}

.navIcon-box:hover {
    background-color: rgb(0, 0, 0);
    cursor: pointer;
}

.Allnav-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.showThis {
    display: none;
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}



@media only screen and (max-width:1263px) {

    .hideThis {
        display: none;
    }


    .navContainer {
        display: flex;
        align-items: center;
        width: 60px;
    }

    .navIcon-box {
        width: max-content;
    }

    .showThis {
        display: block;
    }
}