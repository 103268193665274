.sign-up {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: fit-content;
    color: white;
}


.signup-wrapper {
    border: 1px solid rgb(205, 205, 205);
    padding: 20px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 350px;
    font-family: 'PT Sans', sans-serif;
    background-color: rgb(39, 39, 39);
}

.my-logo {
    font-family: billabong;
    font-size: 2.5rem;
    text-align: center;
}

.logo-message {
    font-size: 1.5rem;
    color: #8e8e8e;
    text-align: center;

}

.signup-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
}

.terms1,
.terms2 {
    color: #8e8e8e;
    text-align: center;
    font-size: 0.8rem;
    margin: 5px;
}

.sign-up-submit {
    background-color: #4bb4f8;
    color: white;
    border: none;
    font-size: 1.5rem;
    width: 90%;
    padding-block: 10px;
    border-radius: 10px;
}

.sign-up-submit:hover {
    cursor: pointer;
    background-color: #1a98ec;
}

/* Already have an account */

.already-account {
    border: 1px solid rgb(205, 205, 205);
    padding: 15px;
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background-color: rgb(39, 39, 39);
}

.log-in {
    color: #4bb4f8;
    text-decoration: underline;
}

.log-in:hover {
    cursor: pointer;
    color: #1a98ec;
}

.try-username {
    color: #8e8e8e;
}

.suggested-username {
    color: #4bb4f8;
    text-decoration: underline;
    width: 200px;
    word-break: break-all;
    overflow: hidden;
}

.suggested-username:hover {
    cursor: pointer;
}