.no-posts-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    font-family: 'PT Sans', sans-serif;
    padding: 20px;
}

.no-posts-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.icon-and-submit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


.image-picker {
    cursor: pointer;
}

.postImg {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 5px;
}