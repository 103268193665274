.no-posts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no-post-svg {
    height: 400px;
    padding: 60px;
}

.no-post-caption {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2.5rem;
}