/* post */

.post-section {
    padding-top: 50px;
}

.post-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 10px;
}

.username {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.post-img {
    width: 100%;
    border-radius: 10px;
}

.post-avatar {
    cursor: pointer;
}

.content {
    position: relative;
}

.heart-box {
    position: absolute;
    top: 35%;
    left: 35%;
    display: none;
    transition: all 0.5s ease;
}

/* post footer */
.post-footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
}

.post-datas {
    display: flex;
    justify-content: space-between;
}

.post-likes {
    display: flex;
    gap: 10px;
}

.comment-input {
    border: none;
    outline: none;
    width: 100%;
    padding: 2px 2px 15px 2px;
    background-color: rgb(39, 39, 39);
    color: white;
    border-bottom: 1px solid grey;

}

.comment-input:focus {
    border-bottom: 1px solid rgb(0, 149, 246);
}

.comment-box {
    position: relative;
}

.comment-btn {
    position: absolute;
    font-size: medium;
    right: 30px;
    outline: none;
    border: none;
    background-color: inherit;
    color: rgb(0, 149, 246);
}

.comment-btn:hover {
    cursor: pointer;
}

.emoji-Icon {
    position: absolute;
    top: 0;
    right: 5px;
}

.emoji-Icon:hover {
    cursor: pointer;
}

.pointer-icon:hover {
    cursor: pointer;
}

.emoji-picker-container {
    position: absolute;
    bottom: 40px;
    right: 5px;
    z-index: 1;
}

.caption-without-image {
    padding: 5px;
    border-radius: 10px;
    margin-block: 5px;
}

.hover-grey:hover {
    color: rgb(197, 197, 197);
    cursor: pointer;
}

.time-ago {
    color: rgb(184, 184, 184);
}

.post-response-count {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}