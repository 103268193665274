.main-section {
    width: 100%;
    background-color: #000;
    padding-right: 20px;
    color: white;
}

.add-post-section {
    display: flex;
    gap: 20px;
    justify-content: center;
    padding-top: 50px;
    align-items: center;
    overflow-x: auto;

}

.icon-and-submit {
    margin-top: 20px;
}

.home-div {
    background-color: black;
}